import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Paper,
  Typography,
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';

import { LoginDialog } from './LoginDialog';
import { makeCredentialResourceManager, makeDbResourceManager } from './ResourceManager';
import { CredentialTokenDialog } from './CredentialTokenDialog';
import { DatabaseItem } from './DatabaseItem';
import { DatabaseNameDialog } from './DatabaseNameDialog';
import { useStyles } from './Styles';

function App() {

  const [loginNeeded, setLoginNeeded] = useState(false);

  const dbs = makeDbResourceManager(setLoginNeeded);
  const credentials = makeCredentialResourceManager(setLoginNeeded);

  const [credentialTokenModal, setCredentialTokenModal] = useState({
    open: false,
    token: "",
  });

  const [dbNameModalOpen, setDbNameModalOpen] = useState(false);
  const [dbName, setDbName] = useState("");

  const classes = useStyles();

  useEffect(() => {
    dbs.refreshState();
    credentials.refreshState();
  }, []);

  return (
    <div>
      <Paper>
        <Box p={2}>
          <Typography variant="h4">
            Databases
        </Typography>
        </Box>

        <Box m={2}>
          {/* Accordion of databases */}
          {dbs.get().map(db => (
            <DatabaseItem id={db.id} name={db.name} dbs={dbs}
              credentials={credentials} setCredentialTokenModal={setCredentialTokenModal} />
          ))}
        </Box>

        {/* Create a new database */}
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          className={classes.actionButton}
          onClick={() => {
            setDbNameModalOpen(true);
            setDbName("");
          }}>
          Database
          </Button>

      </Paper>

      {/* Dialog for naming a database */}
      <DatabaseNameDialog dbs={dbs} dbName={dbName} setDbName={setDbName}
        dbNameModalOpen={dbNameModalOpen} setDbNameModalOpen={setDbNameModalOpen} />

      {/* Dialog for copying credential token */}
      <CredentialTokenDialog credentialTokenModal={credentialTokenModal}
        setCredentialTokenModal={setCredentialTokenModal} />

      {/* Dialog for logging in */}
      <LoginDialog loginNeeded={loginNeeded} setLoginNeeded={setLoginNeeded} />
    </div>
  );
}

export default App;