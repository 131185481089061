import React from 'react';

import {
    Accordion,
    AccordionSummary,
    Box,
    Button,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    Typography,
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useStyles } from './Styles';

function DatabaseItem(props) {
    const classes = useStyles();

    return (
        <Accordion elevation={6}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5" color="textPrimary">
                    {props.name}
                </Typography>
            </AccordionSummary>

            <Box m={1}>
                <Grid container margin={2} direction="row" justify="space-between" >
                    <Box>
                        <Typography variant="h6" className={classes.databaseIdLabel}>
                            Database Id:
                    </Typography>
                        <div className={classes.databaseId}>
                            {props.id}
                        </div>
                    </Box>
                    <Box>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<DeleteIcon />}
                            onClick={() => props.dbs.delete(props.id)}
                            disabled={props.credentials.get().filter(c => c.db_id === props.id).length > 0}
                            className={classes.tableButton}>
                            Delete Database
                  </Button>
                    </Box>
                </Grid>
            </Box>

            <Box p={2}>
                <Typography variant="h6">
                    Credentials
                  </Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant="subtitle1">
                                    Credential Id
                              </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="subtitle1">
                                    Mode
                        </Typography>
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.credentials.get().filter(c => c.db_id ===props.id).map(
                            c => (
                                <TableRow>
                                    <TableCell className={classes.code}>{c.id}</TableCell>
                                    <TableCell>
                                        <Typography variant="overline">
                                            {c.mode}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            startIcon={<DeleteIcon />}
                                            onClick={() => props.credentials.delete(c.id)}
                                            className={classes.tableButton}>
                                            Delete Credential
                              </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </Box>
            <Box>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    className={classes.actionButton}
                    onClick={() => {
                        props.credentials.create({ mode: "read", dbId: props.id }).then(c => {
                            props.setCredentialTokenModal({
                                open: true,
                                token: c.credential_token
                            });
                        });
                    }}>
                    Read Credential
                  </Button>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    className={classes.actionButton}
                    onClick={() => {
                        props.credentials.create({ mode: "write", dbId: props.id }).then(c => {
                            props.setCredentialTokenModal({
                                open: true,
                                token: c.credential_token
                            });
                        });
                    }}>
                    Write Credential
                  </Button>
            </Box>
        </Accordion>
    );
}

export { DatabaseItem };