import React from 'react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';

import { useStyles } from './Styles';

function CredentialTokenDialog(props) {
    const classes = useStyles();
    return (
        <Dialog
            open={props.credentialTokenModal.open}
            onClose={() => props.setCredentialTokenModal({ open: false, token: "" })}>
            <DialogTitle>Token</DialogTitle>
            <DialogContent>
                <DialogContentText className={classes.code}>
                    {props.credentialTokenModal.token}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => props.setCredentialTokenModal({ open: false, token: "" })}>
                    Close
            </Button>
            </DialogActions>
        </Dialog>
    );
}

export { CredentialTokenDialog };