import { useState } from 'react';

function combineUrlAndQueryParams(urlStr, params) {
    const url = new URL(window.location.origin + urlStr);
    if (params) {
        url.search = new URLSearchParams(params).toString();
    }
    return url;
}

function makeDbResourceManager(setLoginNeeded) {
    return new ResourceManager(
        setLoginNeeded,
        args => combineUrlAndQueryParams("/api/db", { name: args.name }),
        "/api/db/",
        "/api/db/list"
    );
}

function makeCredentialResourceManager(setLoginNeeded) {
    return new ResourceManager(
        setLoginNeeded,
      args =>
        combineUrlAndQueryParams(
          `/api/db/${args.dbId}/credential/${args.mode}`,
          args),
        "/api/credential/",
        "/api/credential/list"
    );
}

function without(obj, prop) {
    const res = Object.assign({}, obj);
    delete res[prop];
    return res;
}

class ResourceManager {
    constructor(setLoginNeeded, createPathFun, deletePath, listPath) {
        this.setLoginNeeded = setLoginNeeded;
        [this.state, this.setState] = useState([]);
        this.createPathFun = createPathFun;
        this.deletePath = deletePath;
        this.listPath = listPath;
    }

    async create(args) {
        const createRes = await this.doFetch(this.createPathFun(args), { method: "POST" });
        if (createRes) {
            this.setState([...this.state].concat([without(createRes, "credential_token" )]));
        }
        return createRes;
    }

    async delete(id) {
        const deleteRes = await this.doFetch(this.deletePath + id, { method: "DELETE" });
        if (deleteRes && deleteRes.success) {
            this.setState(this.state.filter(x => x.id !== id));
        }
        return deleteRes && deleteRes.success;
    }

    async list() {
        return this.doFetch(this.listPath);
    }

    async doFetch(url, opts) {
        const extOpts = Object.assign({ redirect: "error" }, opts);
        return fetch(url, extOpts)
            .then(r => r.json())
            .catch(err =>
                fetch("/api/check_login")
                    .then(r => r.json())
                    .then(r => {
                        if (r.logged_in === false) {
                            this.setLoginNeeded(true);
                        } else {
                            return fetch(url, opts);
                        }
                        throw err;
                    }));
    }

    async refreshState() {
        const listRes = await this.list();
        this.setState(listRes["results"]);
    }

    get() {
        return this.state;
    }
}

export { makeDbResourceManager, makeCredentialResourceManager };
