import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    tableButton: {
        margin: theme.spacing(0.25),
    },
    actionButton: {
        margin: theme.spacing(2)
    },
    dialogButton: {
        margin: theme.spacing(1)
    },
    code: {
        fontFamily: "monospace",
        wordWrap: "break-word",
        fontSize: "125%",
    },
    databaseIdLabel: {
        display: "inline-block",
        margin: theme.spacing(0.5),
    },
    databaseId: {
        fontFamily: "monospace",
        wordWrap: "break-word",
        display: "inline-block",
        margin: theme.spacing(0.5),
        fontSize: "125%",
    }
}));

export { useStyles };