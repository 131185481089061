import React from 'react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@material-ui/core';

function DatabaseNameDialog(props) {

    async function createDb() {
        if (props.dbName.trim() === "") {
            return;
        } else {
            return props.dbs.create({ name: props.dbName.trim() }).then(
                () => props.setDbNameModalOpen(false))
        }
    }

    return (
        <Dialog open={props.dbNameModalOpen} onClose={() => props.setDbNameModalOpen(false)}>
            <DialogTitle>Create Database</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => { e.preventDefault(); createDb(); }}>
                    <TextField label="name" autoFocus value={props.dbName}
                        onChange={(e) => props.setDbName(e.target.value)}
                        error={props.dbName.trim() === ""} />
                </form>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary"
                    disabled={props.dbName.trim() === ""} onClick={() => createDb()}>
                    Create
                </Button>
                <Button
                    variant="contained" color="secondary"
                    onClick={() => props.setDbNameModalOpen(false)}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export { DatabaseNameDialog };