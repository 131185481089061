import React from 'react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
} from '@material-ui/core';

function LoginDialog(props) {
    return (
        <Dialog open={props.loginNeeded} onClose={() => props.setLoginNeeded(false)}>
            <DialogTitle>Please log in</DialogTitle>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={() => {
                    window.open(
                        process.env.REACT_APP_OKTA_EMBED_LINK,
                        "_blank");
                    props.setLoginNeeded(false);
                }}>
                    Log In
        </Button>
            </DialogActions>
        </Dialog>
    );
}

export { LoginDialog };
